.dot {
  height: 4.7vh;
  margin: 0 2rem;
  margin-top: 0.3rem;
  background-color: #EBECEC;
  
}

.documents {
  margin-left: 1.8rem;
}
.shared {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10rem;
}
.second-header{
  margin: 0 2rem;
}
.download {
  color: #0056b3;
  cursor: pointer;
}
.files {
  display: flex;
  justify-content: space-between;
}
.pdf {
  height: 2.7vh;
}
.specification {
  height: 2.4vh;
  width: 1rem;
}
.dispersion {
  border: solid;
  border-width: 0.009px;
  width: 14.23rem;
  border-radius: 0.34 rem;
  cursor: pointer;
}
.drawing {
  border: solid;
  border-width: 0.009px;
  width: 14.69rem;
  border-radius: 0.34 rem;
  cursor: pointer;
}
.purhase {
  border: solid;
  border-width: 0.009px;
  width: 14.71rem;
  border-radius: 0.34 rem;
  cursor: pointer;
}
.specificatin {
  border: solid;
  border-width: 0.009px;
  width: 14.75rem;
  border-radius: 0.34 rem;
  cursor: pointer;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  gap: 4rem;
}

.closeImgDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.closee {
  height: 2.1vh;
  width: 1rem;
  display: flex;
  justify-content: flex-end;
}

.inputs {
  width: 96%;
  height: 2.3rem;
  border-radius: 0.3rem;
  padding: 0;
  margin: 0;
  padding: 0.2%;
}

.formInputs {
  padding: 1rem 0;
}
.modal {
  margin-top: 4rem;
  left: 0;
  right: 0;
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-height: 80%;
  overflow-y: auto;
  overflow: auto;
  background-color:#FFFDFD;
  z-index: 50;
  outline: none;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  cursor: pointer;

}
/* .closee {
  height: 2.1vh;
  width: 1rem;
  display: flex;
  margin-left: 23rem;
} */
/* .emailSearch {
  width: 20.2rem;
  height: 2.3rem;
  border-radius: 0.3rem;
} */
/* .body {
  margin: 16rem;
} */
/* .password {
  width: 20.2rem;
  height: 2.3rem;
  border-radius: 0.3rem;
} */
.downloads {
  background-color: #005da6;
  color: white!important;
  padding: .5rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
}
/* .access {
  color:#005da6;
  cursor: pointer;
  
} */

@media (min-width: 640px) {
  .modal {
    margin-top: 20vh;
    border-radius: 10px;
    width: 24rem;
  }
}

@media screen and (max-width: 768px) {
.main {
flex-flow: column;
}
.shared {
padding: 0;}
}
